import React, { useState, useContext } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import closeIcon from "../../assets/images/close-icon.svg"

import SEO from "../../components/seo"

import GlobalContext from "../../context/global-context"

import {
  H2,
  PageContainer,
} from "../../components/common"

const CustomPageContainer = styled(PageContainer)`
  width: 945px !important;

  @media screen and (max-width: 980px) {
    width: 614px !important;
  }

  @media screen and (max-width: 640px) {
    width: 345px !important;
  }
`

const CloseButton = styled.button`
  outline: none;
  box-shadow: none;
  background: url(${closeIcon}) no-repeat center, transparent;
  border: none;
  position: absolute;
  bottom: calc(100% + 20px);
  right: 40px;
  top: 34px;
  height: 16px;
  width: 21px;
  cursor: pointer;
`

const TableContainer = styled.div`
  width: 100%;
  height: 540px;
  overflow-y: scroll;

`
const DisclaimerContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f8f8f7;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Disclaimer = styled.div`
  width: 946px;
  height: 861px;
  background: #fff;
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 40px;
  box-sizing: border-box;
  position: relative;

  ${H2} {
    margin-bottom: 34px;
  }

  @media screen and (max-width: 980px) {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 640px) {
    padding: 30px;
  }

  table, th, td {
    border: 1px solid #D3D3D3;
    border-collapse: collapse;
  }

  table {
    width: 100%;
  }

  th {
    background: #F0F0F0;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    height: 54px;
  }

  td {
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    padding-left: 20px;
    height: 54px;
  }
`

const DisclaimerButtonsContainer = styled.div`
  display: flex;
  margin-top: 70px;

  @media screen and (max-width: 640px) {
    flex-direction: column;
  }

  @media screen and (max-width: 640px) {
    margin-top: 20px;
  }
`

const DisclaimerButton = styled.button`
  width: 215px;
  height: 48px;
  box-sizing: border-box;
  border: 1px solid #000;
  outline: none;
  box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.1);
  margin: 0 22px;
  cursor: pointer;
  background: #fff;
  font-size: 15px;
  font-weight: 700;

  &:hover {
    box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 1);
  }

  @media screen and (max-width: 640px) {
    margin: 10px 0;
  }
`

export default function IPOPage() {
  const [termsAccepted, setTemrsAccepted] = useState(false)
  const globalContext = useContext(GlobalContext)

  return (
    <>
      <SEO
        title="ID cards, passports & documents supported by Checkin.com"
        description="Checkin.com makes it easy to scan and verify over 13,000 types of ID documents, passports, and driver's licenses across 190+ markets."
        canonical="/id-scanner/documents/"
        ogImage={require("/src/assets/images/og/og-id-scan-min.png").default}
      />
      {!termsAccepted && (
        <>

          <CustomPageContainer>
            <DisclaimerContainer>
              <Disclaimer>
                <CloseButton
                  onClick={() => {
                    navigate("/id-scanner")
                  }}
                />
                <H2>Supported documents</H2>
                <TableContainer>
                  <table >
                    <colgroup>
                      <col span="1" style={{ width: '30%' }} />
                      <col span="1" style={{ width: '70%' }} />
                    </colgroup>
                    <thead>
                      <tr >
                        <th>Country</th>
                        <th >Document Type</th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr >
                        <td >Abkhazia</td><td >Driver's Licence</td>
                      </tr><tr >
                        <td >Abkhazia</td><td >Passport</td>
                      </tr><tr class="row-4 even">
                        <td >Afghanistan</td><td >ID Card</td>
                      </tr><tr class="row-5 odd">
                        <td >Afghanistan</td><td >Passport</td>
                      </tr><tr class="row-6 even">
                        <td >Afghanistan</td><td >Visa</td>
                      </tr><tr class="row-7 odd">
                        <td >Albania</td><td >Driver's Licence</td>
                      </tr><tr class="row-8 even">
                        <td >Albania</td><td >ID Card</td>
                      </tr><tr class="row-9 odd">
                        <td >Albania</td><td >Passport</td>
                      </tr><tr class="row-10 even">
                        <td >Algeria</td><td >ID Card</td>
                      </tr><tr class="row-11 odd">
                        <td >Algeria</td><td >Passport</td>
                      </tr><tr class="row-12 even">
                        <td >Algeria</td><td >Visa</td>
                      </tr><tr class="row-13 odd">
                        <td >American Samoa</td><td >Driver's Licence</td>
                      </tr><tr class="row-14 even">
                        <td >American Samoa</td><td >ID Card</td>
                      </tr><tr class="row-15 odd">
                        <td >American Samoa</td><td >Voter Card</td>
                      </tr><tr class="row-16 even">
                        <td >Andorra</td><td >Driver's Licence</td>
                      </tr><tr class="row-17 odd">
                        <td >Andorra</td><td >Passport</td>
                      </tr><tr class="row-18 even">
                        <td >Andorra</td><td >Residence Permit</td>
                      </tr><tr class="row-19 odd">
                        <td >Angola</td><td >Driver's Licence</td>
                      </tr><tr class="row-20 even">
                        <td >Angola</td><td >ID Card</td>
                      </tr><tr class="row-21 odd">
                        <td >Angola</td><td >Passport</td>
                      </tr><tr class="row-22 even">
                        <td >Angola</td><td >Residence Permit</td>
                      </tr><tr class="row-23 odd">
                        <td >Angola</td><td >Visa</td>
                      </tr><tr class="row-24 even">
                        <td >Anguilla</td><td >Driver's Licence</td>
                      </tr><tr class="row-25 odd">
                        <td >Anguilla</td><td >Passport</td>
                      </tr><tr class="row-26 even">
                        <td >Antigua and Barbuda</td><td >Driver's Licence</td>
                      </tr><tr class="row-27 odd">
                        <td >Antigua and Barbuda</td><td >ID Card</td>
                      </tr><tr class="row-28 even">
                        <td >Antigua and Barbuda</td><td >Passport</td>
                      </tr><tr class="row-29 odd">
                        <td >Antigua and Barbuda</td><td >Social Security Card</td>
                      </tr><tr class="row-30 even">
                        <td >Argentina</td><td >Driver's Licence</td>
                      </tr><tr class="row-31 odd">
                        <td >Argentina</td><td >ID Card</td>
                      </tr><tr class="row-32 even">
                        <td >Argentina</td><td >Passport</td>
                      </tr><tr class="row-33 odd">
                        <td >Argentina</td><td >Visa</td>
                      </tr><tr class="row-34 even">
                        <td >Armenia</td><td >Driver's Licence</td>
                      </tr><tr class="row-35 odd">
                        <td >Armenia</td><td >ID Card</td>
                      </tr><tr class="row-36 even">
                        <td >Armenia</td><td >Passport</td>
                      </tr><tr class="row-37 odd">
                        <td >Armenia</td><td >Registration Certificate</td>
                      </tr><tr class="row-38 even">
                        <td >Armenia</td><td >Visa</td>
                      </tr><tr class="row-39 odd">
                        <td >Aruba</td><td >Driver's Licence</td>
                      </tr><tr class="row-40 even">
                        <td >Aruba</td><td >ID Card</td>
                      </tr><tr class="row-41 odd">
                        <td >Aruba</td><td >Visa</td>
                      </tr><tr class="row-42 even">
                        <td >Australia</td><td >Driver's Licence</td>
                      </tr><tr class="row-43 odd">
                        <td >Australia</td><td >ID Card</td>
                      </tr><tr class="row-44 even">
                        <td >Australia</td><td >Passport</td>
                      </tr><tr class="row-45 odd">
                        <td >Australia</td><td >Proof of Age</td>
                      </tr><tr class="row-46 even">
                        <td >Australia</td><td >Residence Permit</td>
                      </tr><tr class="row-47 odd">
                        <td >Australia</td><td >Visa</td>
                      </tr><tr class="row-48 even">
                        <td >Austria</td><td >Driver's Licence</td>
                      </tr><tr class="row-49 odd">
                        <td >Austria</td><td >ID Card</td>
                      </tr><tr class="row-50 even">
                        <td >Austria</td><td >Passport</td>
                      </tr><tr class="row-51 odd">
                        <td >Austria</td><td >Registration Certificate</td>
                      </tr><tr class="row-52 even">
                        <td >Austria</td><td >Residence Permit</td>
                      </tr><tr class="row-53 odd">
                        <td >Austria</td><td >Visa</td>
                      </tr><tr class="row-54 even">
                        <td >Azerbaijan</td><td >Driver's Licence</td>
                      </tr><tr class="row-55 odd">
                        <td >Azerbaijan</td><td >ID Card</td>
                      </tr><tr class="row-56 even">
                        <td >Azerbaijan</td><td >Passport</td>
                      </tr><tr class="row-57 odd">
                        <td >Azerbaijan</td><td >Registration Certificate</td>
                      </tr><tr class="row-58 even">
                        <td >Azerbaijan</td><td >Residence Permit</td>
                      </tr><tr class="row-59 odd">
                        <td >Azerbaijan</td><td >Visa</td>
                      </tr><tr class="row-60 even">
                        <td >Bahamas</td><td >Driver's Licence</td>
                      </tr><tr class="row-61 odd">
                        <td >Bahamas</td><td >ID Card</td>
                      </tr><tr class="row-62 even">
                        <td >Bahamas</td><td >Passport</td>
                      </tr><tr class="row-63 odd">
                        <td >Bahamas</td><td >Residence Permit</td>
                      </tr><tr class="row-64 even">
                        <td >Bahamas</td><td >Visa</td>
                      </tr><tr class="row-65 odd">
                        <td >Bahamas</td><td >Work Permit</td>
                      </tr><tr class="row-66 even">
                        <td >Bahrain</td><td >Driver's Licence</td>
                      </tr><tr class="row-67 odd">
                        <td >Bahrain</td><td >ID Card</td>
                      </tr><tr class="row-68 even">
                        <td >Bahrain</td><td >Passport</td>
                      </tr><tr class="row-69 odd">
                        <td >Bahrain</td><td >Residence Permit</td>
                      </tr><tr class="row-70 even">
                        <td >Bangladesh</td><td >Driver's Licence</td>
                      </tr><tr class="row-71 odd">
                        <td >Bangladesh</td><td >ID Card</td>
                      </tr><tr class="row-72 even">
                        <td >Bangladesh</td><td >Passport</td>
                      </tr><tr class="row-73 odd">
                        <td >Bangladesh</td><td >Visa</td>
                      </tr><tr class="row-74 even">
                        <td >Barbados</td><td >Driver's Licence</td>
                      </tr><tr class="row-75 odd">
                        <td >Barbados</td><td >ID Card</td>
                      </tr><tr class="row-76 even">
                        <td >Barbados</td><td >Passport</td>
                      </tr><tr class="row-77 odd">
                        <td >Belarus</td><td >Driver's Licence</td>
                      </tr><tr class="row-78 even">
                        <td >Belarus</td><td >ID Card</td>
                      </tr><tr class="row-79 odd">
                        <td >Belarus</td><td >Passport</td>
                      </tr><tr class="row-80 even">
                        <td >Belarus</td><td >Registration Certificate</td>
                      </tr><tr class="row-81 odd">
                        <td >Belarus</td><td >Residence Permit</td>
                      </tr><tr class="row-82 even">
                        <td >Belarus</td><td >Social Security Card</td>
                      </tr><tr class="row-83 odd">
                        <td >Belarus</td><td >Visa</td>
                      </tr><tr class="row-84 even">
                        <td >Belgium</td><td >Driver's Licence</td>
                      </tr><tr class="row-85 odd">
                        <td >Belgium</td><td >ID Card</td>
                      </tr><tr class="row-86 even">
                        <td >Belgium</td><td >Passport</td>
                      </tr><tr class="row-87 odd">
                        <td >Belgium</td><td >Registration Certificate</td>
                      </tr><tr class="row-88 even">
                        <td >Belgium</td><td >Residence Permit</td>
                      </tr><tr class="row-89 odd">
                        <td >Belgium</td><td >Visa</td>
                      </tr><tr class="row-90 even">
                        <td >Belize</td><td >Driver's Licence</td>
                      </tr><tr class="row-91 odd">
                        <td >Belize</td><td >Passport</td>
                      </tr><tr class="row-92 even">
                        <td >Belize</td><td >Residence Permit</td>
                      </tr><tr class="row-93 odd">
                        <td >Belize</td><td >Visa</td>
                      </tr><tr class="row-94 even">
                        <td >Belize</td><td >Work Permit</td>
                      </tr><tr class="row-95 odd">
                        <td >Benin</td><td >Driver's Licence</td>
                      </tr><tr class="row-96 even">
                        <td >Benin</td><td >ID Card</td>
                      </tr><tr class="row-97 odd">
                        <td >Benin</td><td >Passport</td>
                      </tr><tr class="row-98 even">
                        <td >Benin</td><td >Residence Permit</td>
                      </tr><tr class="row-99 odd">
                        <td >Benin</td><td >Visa</td>
                      </tr><tr class="row-100 even">
                        <td >Bermuda</td><td >Driver's Licence</td>
                      </tr><tr class="row-101 odd">
                        <td >Bermuda</td><td >ID Card</td>
                      </tr><tr class="row-102 even">
                        <td >Bermuda</td><td >Passport</td>
                      </tr><tr class="row-103 odd">
                        <td >Bhutan</td><td >Passport</td>
                      </tr><tr class="row-104 even">
                        <td >Bhutan</td><td >Voter Card</td>
                      </tr><tr class="row-105 odd">
                        <td >Bolivia</td><td >Driver's Licence</td>
                      </tr><tr class="row-106 even">
                        <td >Bolivia</td><td >ID Card</td>
                      </tr><tr class="row-107 odd">
                        <td >Bolivia</td><td >Passport</td>
                      </tr><tr class="row-108 even">
                        <td >Bolivia</td><td >Visa</td>
                      </tr><tr class="row-109 odd">
                        <td >Bonaire, Sint Eustatius and Saba</td><td >Driver's Licence</td>
                      </tr><tr class="row-110 even">
                        <td >Bonaire, Sint Eustatius and Saba</td><td >ID Card</td>
                      </tr><tr class="row-111 odd">
                        <td >Bonaire, Sint Eustatius and Saba</td><td >Visa</td>
                      </tr><tr class="row-112 even">
                        <td >Bosnia and Herzegovina</td><td >Driver's Licence</td>
                      </tr><tr class="row-113 odd">
                        <td >Bosnia and Herzegovina</td><td >ID Card</td>
                      </tr><tr class="row-114 even">
                        <td >Bosnia and Herzegovina</td><td >Passport</td>
                      </tr><tr class="row-115 odd">
                        <td >Bosnia and Herzegovina</td><td >Visa</td>
                      </tr><tr class="row-116 even">
                        <td >Botswana</td><td >Driver's Licence</td>
                      </tr><tr class="row-117 odd">
                        <td >Botswana</td><td >ID Card</td>
                      </tr><tr class="row-118 even">
                        <td >Botswana</td><td >Passport</td>
                      </tr><tr class="row-119 odd">
                        <td >Botswana</td><td >Residence Permit</td>
                      </tr><tr class="row-120 even">
                        <td >Brazil</td><td >Driver's Licence</td>
                      </tr><tr class="row-121 odd">
                        <td >Brazil</td><td >ID Card</td>
                      </tr><tr class="row-122 even">
                        <td >Brazil</td><td >Passport</td>
                      </tr><tr class="row-123 odd">
                        <td >Brazil</td><td >Visa</td>
                      </tr><tr class="row-124 even">
                        <td >Brazil</td><td >Work Permit</td>
                      </tr><tr class="row-125 odd">
                        <td >Brunei Darussalam</td><td >Driver's Licence</td>
                      </tr><tr class="row-126 even">
                        <td >Brunei Darussalam</td><td >ID Card</td>
                      </tr><tr class="row-127 odd">
                        <td >Brunei Darussalam</td><td >Passport</td>
                      </tr><tr class="row-128 even">
                        <td >Bulgaria</td><td >Driver's Licence</td>
                      </tr><tr class="row-129 odd">
                        <td >Bulgaria</td><td >ID Card</td>
                      </tr><tr class="row-130 even">
                        <td >Bulgaria</td><td >Passport</td>
                      </tr><tr class="row-131 odd">
                        <td >Bulgaria</td><td >Registration Certificate</td>
                      </tr><tr class="row-132 even">
                        <td >Bulgaria</td><td >Residence Permit</td>
                      </tr><tr class="row-133 odd">
                        <td >Bulgaria</td><td >Visa</td>
                      </tr><tr class="row-134 even">
                        <td >Burkina Faso</td><td >Driver's Licence</td>
                      </tr><tr class="row-135 odd">
                        <td >Burkina Faso</td><td >ID Card</td>
                      </tr><tr class="row-136 even">
                        <td >Burkina Faso</td><td >Passport</td>
                      </tr><tr class="row-137 odd">
                        <td >Burkina Faso</td><td >Visa</td>
                      </tr><tr class="row-138 even">
                        <td >Burundi</td><td >Passport</td>
                      </tr><tr class="row-139 odd">
                        <td >Burundi</td><td >Visa</td>
                      </tr><tr class="row-140 even">
                        <td >Cambodia</td><td >Driver's Licence</td>
                      </tr><tr class="row-141 odd">
                        <td >Cambodia</td><td >ID Card</td>
                      </tr><tr class="row-142 even">
                        <td >Cambodia</td><td >Passport</td>
                      </tr><tr class="row-143 odd">
                        <td >Cambodia</td><td >Registration Certificate</td>
                      </tr><tr class="row-144 even">
                        <td >Cambodia</td><td >Visa</td>
                      </tr><tr class="row-145 odd">
                        <td >Cambodia</td><td >Work Permit</td>
                      </tr><tr class="row-146 even">
                        <td >Cameroon</td><td >Driver's Licence</td>
                      </tr><tr class="row-147 odd">
                        <td >Cameroon</td><td >ID Card</td>
                      </tr><tr class="row-148 even">
                        <td >Cameroon</td><td >Passport</td>
                      </tr><tr class="row-149 odd">
                        <td >Cameroon</td><td >Residence Permit</td>
                      </tr><tr class="row-150 even">
                        <td >Cameroon</td><td >Visa</td>
                      </tr><tr class="row-151 odd">
                        <td >Canada</td><td >Driver's Licence</td>
                      </tr><tr class="row-152 even">
                        <td >Canada</td><td >ID Card</td>
                      </tr><tr class="row-153 odd">
                        <td >Canada</td><td >Passport</td>
                      </tr><tr class="row-154 even">
                        <td >Canada</td><td >Residence Permit</td>
                      </tr><tr class="row-155 odd">
                        <td >Canada</td><td >Visa</td>
                      </tr><tr class="row-156 even">
                        <td >Canada</td><td >Work Permit</td>
                      </tr><tr class="row-157 odd">
                        <td >Cape Verde</td><td >Driver's Licence</td>
                      </tr><tr class="row-158 even">
                        <td >Cape Verde</td><td >ID Card</td>
                      </tr><tr class="row-159 odd">
                        <td >Cape Verde</td><td >Passport</td>
                      </tr><tr class="row-160 even">
                        <td >Cape Verde</td><td >Visa</td>
                      </tr><tr class="row-161 odd">
                        <td >Cayman Islands</td><td >Driver's Licence</td>
                      </tr><tr class="row-162 even">
                        <td >Cayman Islands</td><td >Passport</td>
                      </tr><tr class="row-163 odd">
                        <td >Central African Republic</td><td >Driver's Licence</td>
                      </tr><tr class="row-164 even">
                        <td >Central African Republic</td><td >ID Card</td>
                      </tr><tr class="row-165 odd">
                        <td >Central African Republic</td><td >Passport</td>
                      </tr><tr class="row-166 even">
                        <td >Chad</td><td >Driver's Licence</td>
                      </tr><tr class="row-167 odd">
                        <td >Chad</td><td >ID Card</td>
                      </tr><tr class="row-168 even">
                        <td >Chad</td><td >Passport</td>
                      </tr><tr class="row-169 odd">
                        <td >Chad</td><td >Visa</td>
                      </tr><tr class="row-170 even">
                        <td >Chile</td><td >Driver's Licence</td>
                      </tr><tr class="row-171 odd">
                        <td >Chile</td><td >ID Card</td>
                      </tr><tr class="row-172 even">
                        <td >Chile</td><td >Passport</td>
                      </tr><tr class="row-173 odd">
                        <td >Chile</td><td >Residence Permit</td>
                      </tr><tr class="row-174 even">
                        <td >Chile</td><td >Visa</td>
                      </tr><tr class="row-175 odd">
                        <td >China</td><td >Driver's Licence</td>
                      </tr><tr class="row-176 even">
                        <td >China</td><td >ID Card</td>
                      </tr><tr class="row-177 odd">
                        <td >China</td><td >Passport</td>
                      </tr><tr class="row-178 even">
                        <td >China</td><td >Residence Permit</td>
                      </tr><tr class="row-179 odd">
                        <td >China</td><td >Visa</td>
                      </tr><tr class="row-180 even">
                        <td >China</td><td >Work Permit</td>
                      </tr><tr class="row-181 odd">
                        <td >Colombia</td><td >Driver's Licence</td>
                      </tr><tr class="row-182 even">
                        <td >Colombia</td><td >ID Card</td>
                      </tr><tr class="row-183 odd">
                        <td >Colombia</td><td >Passport</td>
                      </tr><tr class="row-184 even">
                        <td >Colombia</td><td >Registration Certificate</td>
                      </tr><tr class="row-185 odd">
                        <td >Colombia</td><td >Residence Permit</td>
                      </tr><tr class="row-186 even">
                        <td >Colombia</td><td >Visa</td>
                      </tr><tr class="row-187 odd">
                        <td >Comoros</td><td >ID Card</td>
                      </tr><tr class="row-188 even">
                        <td >Comoros</td><td >Passport</td>
                      </tr><tr class="row-189 odd">
                        <td >Comoros</td><td >Residence Permit</td>
                      </tr><tr class="row-190 even">
                        <td >Comoros</td><td >Visa</td>
                      </tr><tr class="row-191 odd">
                        <td >Congo</td><td >Driver's Licence</td>
                      </tr><tr class="row-192 even">
                        <td >Congo</td><td >ID Card</td>
                      </tr><tr class="row-193 odd">
                        <td >Congo</td><td >Passport</td>
                      </tr><tr class="row-194 even">
                        <td >Congo</td><td >Residence Permit</td>
                      </tr><tr class="row-195 odd">
                        <td >Congo</td><td >Visa</td>
                      </tr><tr class="row-196 even">
                        <td >Congo, the Democratic Republic of the</td><td >Driver's Licence</td>
                      </tr><tr class="row-197 odd">
                        <td >Congo, the Democratic Republic of the</td><td >Passport</td>
                      </tr><tr class="row-198 even">
                        <td >Congo, the Democratic Republic of the</td><td >Visa</td>
                      </tr><tr class="row-199 odd">
                        <td >Congo, the Democratic Republic of the</td><td >Voter Card</td>
                      </tr><tr class="row-200 even">
                        <td >Cook Islands</td><td >Driver's Licence</td>
                      </tr><tr class="row-201 odd">
                        <td >Costa Rica</td><td >Driver's Licence</td>
                      </tr><tr class="row-202 even">
                        <td >Costa Rica</td><td >ID Card</td>
                      </tr><tr class="row-203 odd">
                        <td >Costa Rica</td><td >Passport</td>
                      </tr><tr class="row-204 even">
                        <td >Costa Rica</td><td >Visa</td>
                      </tr><tr class="row-205 odd">
                        <td >Cote D'Ivoire</td><td >Driver's Licence</td>
                      </tr><tr class="row-206 even">
                        <td >Cote D'Ivoire</td><td >ID Card</td>
                      </tr><tr class="row-207 odd">
                        <td >Cote D'Ivoire</td><td >Passport</td>
                      </tr><tr class="row-208 even">
                        <td >Cote D'Ivoire</td><td >Residence Permit</td>
                      </tr><tr class="row-209 odd">
                        <td >Cote D'Ivoire</td><td >Visa</td>
                      </tr><tr class="row-210 even">
                        <td >Croatia</td><td >Driver's Licence</td>
                      </tr><tr class="row-211 odd">
                        <td >Croatia</td><td >ID Card</td>
                      </tr><tr class="row-212 even">
                        <td >Croatia</td><td >Passport</td>
                      </tr><tr class="row-213 odd">
                        <td >Croatia</td><td >Residence Permit</td>
                      </tr><tr class="row-214 even">
                        <td >Croatia</td><td >Visa</td>
                      </tr><tr class="row-215 odd">
                        <td >Cuba</td><td >Driver's Licence</td>
                      </tr><tr class="row-216 even">
                        <td >Cuba</td><td >ID Card</td>
                      </tr><tr class="row-217 odd">
                        <td >Cuba</td><td >Passport</td>
                      </tr><tr class="row-218 even">
                        <td >Cuba</td><td >Visa</td>
                      </tr><tr class="row-219 odd">
                        <td >Curacao</td><td >Driver's Licence</td>
                      </tr><tr class="row-220 even">
                        <td >Curacao</td><td >Visa</td>
                      </tr><tr class="row-221 odd">
                        <td >Cyprus</td><td >Driver's Licence</td>
                      </tr><tr class="row-222 even">
                        <td >Cyprus</td><td >ID Card</td>
                      </tr><tr class="row-223 odd">
                        <td >Cyprus</td><td >Passport</td>
                      </tr><tr class="row-224 even">
                        <td >Cyprus</td><td >Residence Permit</td>
                      </tr><tr class="row-225 odd">
                        <td >Cyprus</td><td >Visa</td>
                      </tr><tr class="row-226 even">
                        <td >Czech Republic</td><td >Driver's Licence</td>
                      </tr><tr class="row-227 odd">
                        <td >Czech Republic</td><td >ID Card</td>
                      </tr><tr class="row-228 even">
                        <td >Czech Republic</td><td >Passport</td>
                      </tr><tr class="row-229 odd">
                        <td >Czech Republic</td><td >Registration Certificate</td>
                      </tr><tr class="row-230 even">
                        <td >Czech Republic</td><td >Residence Permit</td>
                      </tr><tr class="row-231 odd">
                        <td >Czech Republic</td><td >Visa</td>
                      </tr><tr class="row-232 even">
                        <td >Denmark</td><td >Driver's Licence</td>
                      </tr><tr class="row-233 odd">
                        <td >Denmark</td><td >Passport</td>
                      </tr><tr class="row-234 even">
                        <td >Denmark</td><td >Residence Permit</td>
                      </tr><tr class="row-235 odd">
                        <td >Denmark</td><td >Visa</td>
                      </tr><tr class="row-236 even">
                        <td >Djibouti</td><td >ID Card</td>
                      </tr><tr class="row-237 odd">
                        <td >Djibouti</td><td >Passport</td>
                      </tr><tr class="row-238 even">
                        <td >Djibouti</td><td >Visa</td>
                      </tr><tr class="row-239 odd">
                        <td >Dominica</td><td >Driver's Licence</td>
                      </tr><tr class="row-240 even">
                        <td >Dominica</td><td >Passport</td>
                      </tr><tr class="row-241 odd">
                        <td >Dominican Republic</td><td >Driver's Licence</td>
                      </tr><tr class="row-242 even">
                        <td >Dominican Republic</td><td >ID Card</td>
                      </tr><tr class="row-243 odd">
                        <td >Dominican Republic</td><td >Passport</td>
                      </tr><tr class="row-244 even">
                        <td >Dominican Republic</td><td >Residence Permit</td>
                      </tr><tr class="row-245 odd">
                        <td >Dominican Republic</td><td >Visa</td>
                      </tr><tr class="row-246 even">
                        <td >Ecuador</td><td >Driver's Licence</td>
                      </tr><tr class="row-247 odd">
                        <td >Ecuador</td><td >ID Card</td>
                      </tr><tr class="row-248 even">
                        <td >Ecuador</td><td >Passport</td>
                      </tr><tr class="row-249 odd">
                        <td >Ecuador</td><td >Visa</td>
                      </tr><tr class="row-250 even">
                        <td >Egypt</td><td >Driver's Licence</td>
                      </tr><tr class="row-251 odd">
                        <td >Egypt</td><td >ID Card</td>
                      </tr><tr class="row-252 even">
                        <td >Egypt</td><td >Passport</td>
                      </tr><tr class="row-253 odd">
                        <td >Egypt</td><td >Residence Permit</td>
                      </tr><tr class="row-254 even">
                        <td >Egypt</td><td >Visa</td>
                      </tr><tr class="row-255 odd">
                        <td >El Salvador</td><td >Driver's Licence</td>
                      </tr><tr class="row-256 even">
                        <td >El Salvador</td><td >ID Card</td>
                      </tr><tr class="row-257 odd">
                        <td >El Salvador</td><td >Passport</td>
                      </tr><tr class="row-258 even">
                        <td >Equatorial Guinea</td><td >ID Card</td>
                      </tr><tr class="row-259 odd">
                        <td >Equatorial Guinea</td><td >Passport</td>
                      </tr><tr class="row-260 even">
                        <td >Equatorial Guinea</td><td >Visa</td>
                      </tr><tr class="row-261 odd">
                        <td >Eritrea</td><td >Passport</td>
                      </tr><tr class="row-262 even">
                        <td >Eritrea</td><td >Visa</td>
                      </tr><tr class="row-263 odd">
                        <td >Estonia</td><td >Driver's Licence</td>
                      </tr><tr class="row-264 even">
                        <td >Estonia</td><td >ID Card</td>
                      </tr><tr class="row-265 odd">
                        <td >Estonia</td><td >Passport</td>
                      </tr><tr class="row-266 even">
                        <td >Estonia</td><td >Residence Permit</td>
                      </tr><tr class="row-267 odd">
                        <td >Estonia</td><td >Visa</td>
                      </tr><tr class="row-268 even">
                        <td >Ethiopia</td><td >ID Card</td>
                      </tr><tr class="row-269 odd">
                        <td >Ethiopia</td><td >Passport</td>
                      </tr><tr class="row-270 even">
                        <td >Ethiopia</td><td >Residence Permit</td>
                      </tr><tr class="row-271 odd">
                        <td >Ethiopia</td><td >Visa</td>
                      </tr><tr class="row-272 even">
                        <td >Faroe Islands</td><td >Driver's Licence</td>
                      </tr><tr class="row-273 odd">
                        <td >Fiji</td><td >Driver's Licence</td>
                      </tr><tr class="row-274 even">
                        <td >Fiji</td><td >Passport</td>
                      </tr><tr class="row-275 odd">
                        <td >Finland</td><td >Driver's Licence</td>
                      </tr><tr class="row-276 even">
                        <td >Finland</td><td >ID Card</td>
                      </tr><tr class="row-277 odd">
                        <td >Finland</td><td >Passport</td>
                      </tr><tr class="row-278 even">
                        <td >Finland</td><td >Residence Permit</td>
                      </tr><tr class="row-279 odd">
                        <td >Finland</td><td >Visa</td>
                      </tr><tr class="row-280 even">
                        <td >France</td><td >Driver's Licence</td>
                      </tr><tr class="row-281 odd">
                        <td >France</td><td >ID Card</td>
                      </tr><tr class="row-282 even">
                        <td >France</td><td >Passport</td>
                      </tr><tr class="row-283 odd">
                        <td >France</td><td >Registration Certificate</td>
                      </tr><tr class="row-284 even">
                        <td >France</td><td >Residence Permit</td>
                      </tr><tr class="row-285 odd">
                        <td >France</td><td >Visa</td>
                      </tr><tr class="row-286 even">
                        <td >French Polynesia</td><td >Driver's Licence</td>
                      </tr><tr class="row-287 odd">
                        <td >Gabon</td><td >ID Card</td>
                      </tr><tr class="row-288 even">
                        <td >Gabon</td><td >Passport</td>
                      </tr><tr class="row-289 odd">
                        <td >Gabon</td><td >Residence Permit</td>
                      </tr><tr class="row-290 even">
                        <td >Gabon</td><td >Visa</td>
                      </tr><tr class="row-291 odd">
                        <td >Gambia</td><td >Driver's Licence</td>
                      </tr><tr class="row-292 even">
                        <td >Gambia</td><td >ID Card</td>
                      </tr><tr class="row-293 odd">
                        <td >Gambia</td><td >Passport</td>
                      </tr><tr class="row-294 even">
                        <td >Gambia</td><td >Residence Permit</td>
                      </tr><tr class="row-295 odd">
                        <td >Georgia</td><td >Driver's Licence</td>
                      </tr><tr class="row-296 even">
                        <td >Georgia</td><td >ID Card</td>
                      </tr><tr class="row-297 odd">
                        <td >Georgia</td><td >Passport</td>
                      </tr><tr class="row-298 even">
                        <td >Georgia</td><td >Registration Certificate</td>
                      </tr><tr class="row-299 odd">
                        <td >Georgia</td><td >Residence Permit</td>
                      </tr><tr class="row-300 even">
                        <td >Georgia</td><td >Visa</td>
                      </tr><tr class="row-301 odd">
                        <td >Germany</td><td >Driver's Licence</td>
                      </tr><tr class="row-302 even">
                        <td >Germany</td><td >ID Card</td>
                      </tr><tr class="row-303 odd">
                        <td >Germany</td><td >Passport</td>
                      </tr><tr class="row-304 even">
                        <td >Germany</td><td >Residence Permit</td>
                      </tr><tr class="row-305 odd">
                        <td >Germany</td><td >Visa</td>
                      </tr><tr class="row-306 even">
                        <td >Ghana</td><td >Driver's Licence</td>
                      </tr><tr class="row-307 odd">
                        <td >Ghana</td><td >ID Card</td>
                      </tr><tr class="row-308 even">
                        <td >Ghana</td><td >Passport</td>
                      </tr><tr class="row-309 odd">
                        <td >Ghana</td><td >Residence Permit</td>
                      </tr><tr class="row-310 even">
                        <td >Ghana</td><td >Social Security Card</td>
                      </tr><tr class="row-311 odd">
                        <td >Ghana</td><td >Visa</td>
                      </tr><tr class="row-312 even">
                        <td >Ghana</td><td >Voter Card</td>
                      </tr><tr class="row-313 odd">
                        <td >Gibraltar</td><td >ID Card</td>
                      </tr><tr class="row-314 even">
                        <td >Gibraltar</td><td >Registration Certificate</td>
                      </tr><tr class="row-315 odd">
                        <td >Greece</td><td >Driver's Licence</td>
                      </tr><tr class="row-316 even">
                        <td >Greece</td><td >ID Card</td>
                      </tr><tr class="row-317 odd">
                        <td >Greece</td><td >Passport</td>
                      </tr><tr class="row-318 even">
                        <td >Greece</td><td >Residence Permit</td>
                      </tr><tr class="row-319 odd">
                        <td >Greece</td><td >Visa</td>
                      </tr><tr class="row-320 even">
                        <td >Greenland</td><td >Driver's Licence</td>
                      </tr><tr class="row-321 odd">
                        <td >Greenland</td><td >ID Card</td>
                      </tr><tr class="row-322 even">
                        <td >Grenada</td><td >Driver's Licence</td>
                      </tr><tr class="row-323 odd">
                        <td >Grenada</td><td >Passport</td>
                      </tr><tr class="row-324 even">
                        <td >Grenada</td><td >Voter Card</td>
                      </tr><tr class="row-325 odd">
                        <td >Guam</td><td >Driver's Licence</td>
                      </tr><tr class="row-326 even">
                        <td >Guam</td><td >ID Card</td>
                      </tr><tr class="row-327 odd">
                        <td >Guatemala</td><td >Driver's Licence</td>
                      </tr><tr class="row-328 even">
                        <td >Guatemala</td><td >ID Card</td>
                      </tr><tr class="row-329 odd">
                        <td >Guatemala</td><td >Passport</td>
                      </tr><tr class="row-330 even">
                        <td >Guernsey</td><td >Driver's Licence</td>
                      </tr><tr class="row-331 odd">
                        <td >Guinea</td><td >Driver's Licence</td>
                      </tr><tr class="row-332 even">
                        <td >Guinea</td><td >ID Card</td>
                      </tr><tr class="row-333 odd">
                        <td >Guinea</td><td >Passport</td>
                      </tr><tr class="row-334 even">
                        <td >Guinea</td><td >Visa</td>
                      </tr><tr class="row-335 odd">
                        <td >Guinea</td><td >Voter Card</td>
                      </tr><tr class="row-336 even">
                        <td >Guinea-Bissau</td><td >Driver's Licence</td>
                      </tr><tr class="row-337 odd">
                        <td >Guinea-Bissau</td><td >ID Card</td>
                      </tr><tr class="row-338 even">
                        <td >Guinea-Bissau</td><td >Passport</td>
                      </tr><tr class="row-339 odd">
                        <td >Guyana</td><td >Driver's Licence</td>
                      </tr><tr class="row-340 even">
                        <td >Guyana</td><td >ID Card</td>
                      </tr><tr class="row-341 odd">
                        <td >Guyana</td><td >Passport</td>
                      </tr><tr class="row-342 even">
                        <td >Haiti</td><td >Driver's Licence</td>
                      </tr><tr class="row-343 odd">
                        <td >Haiti</td><td >ID Card</td>
                      </tr><tr class="row-344 even">
                        <td >Haiti</td><td >Passport</td>
                      </tr><tr class="row-345 odd">
                        <td >Haiti</td><td >Residence Permit</td>
                      </tr><tr class="row-346 even">
                        <td >Holy See (Vatican City State)</td><td >Passport</td>
                      </tr><tr class="row-347 odd">
                        <td >Honduras</td><td >Driver's Licence</td>
                      </tr><tr class="row-348 even">
                        <td >Honduras</td><td >ID Card</td>
                      </tr><tr class="row-349 odd">
                        <td >Honduras</td><td >Passport</td>
                      </tr><tr class="row-350 even">
                        <td >Honduras</td><td >Residence Permit</td>
                      </tr><tr class="row-351 odd">
                        <td >Hong Kong</td><td >Driver's Licence</td>
                      </tr><tr class="row-352 even">
                        <td >Hong Kong</td><td >ID Card</td>
                      </tr><tr class="row-353 odd">
                        <td >Hong Kong</td><td >Passport</td>
                      </tr><tr class="row-354 even">
                        <td >Hong Kong</td><td >Visa</td>
                      </tr><tr class="row-355 odd">
                        <td >Hungary</td><td >Driver's Licence</td>
                      </tr><tr class="row-356 even">
                        <td >Hungary</td><td >ID Card</td>
                      </tr><tr class="row-357 odd">
                        <td >Hungary</td><td >Passport</td>
                      </tr><tr class="row-358 even">
                        <td >Hungary</td><td >Registration Certificate</td>
                      </tr><tr class="row-359 odd">
                        <td >Hungary</td><td >Residence Permit</td>
                      </tr><tr class="row-360 even">
                        <td >Hungary</td><td >Visa</td>
                      </tr><tr class="row-361 odd">
                        <td >Iceland</td><td >Driver's Licence</td>
                      </tr><tr class="row-362 even">
                        <td >Iceland</td><td >Passport</td>
                      </tr><tr class="row-363 odd">
                        <td >Iceland</td><td >Residence Permit</td>
                      </tr><tr class="row-364 even">
                        <td >Iceland</td><td >Visa</td>
                      </tr><tr class="row-365 odd">
                        <td >India</td><td >Driver's Licence</td>
                      </tr><tr class="row-366 even">
                        <td >India</td><td >ID Card</td>
                      </tr><tr class="row-367 odd">
                        <td >India</td><td >Passport</td>
                      </tr><tr class="row-368 even">
                        <td >India</td><td >Residence Permit</td>
                      </tr><tr class="row-369 odd">
                        <td >India</td><td >Visa</td>
                      </tr><tr class="row-370 even">
                        <td >India</td><td >Voter Card</td>
                      </tr><tr class="row-371 odd">
                        <td >Indonesia</td><td >Driver's Licence</td>
                      </tr><tr class="row-372 even">
                        <td >Indonesia</td><td >ID Card</td>
                      </tr><tr class="row-373 odd">
                        <td >Indonesia</td><td >Passport</td>
                      </tr><tr class="row-374 even">
                        <td >Indonesia</td><td >Residence Permit</td>
                      </tr><tr class="row-375 odd">
                        <td >Indonesia</td><td >Visa</td>
                      </tr><tr class="row-376 even">
                        <td >Iran, Islamic Republic of</td><td >Driver's Licence</td>
                      </tr><tr class="row-377 odd">
                        <td >Iran, Islamic Republic of</td><td >ID Card</td>
                      </tr><tr class="row-378 even">
                        <td >Iran, Islamic Republic of</td><td >Passport</td>
                      </tr><tr class="row-379 odd">
                        <td >Iran, Islamic Republic of</td><td >Residence Permit</td>
                      </tr><tr class="row-380 even">
                        <td >Iran, Islamic Republic of</td><td >Visa</td>
                      </tr><tr class="row-381 odd">
                        <td >Iraq</td><td >Driver's Licence</td>
                      </tr><tr class="row-382 even">
                        <td >Iraq</td><td >ID Card</td>
                      </tr><tr class="row-383 odd">
                        <td >Iraq</td><td >Passport</td>
                      </tr><tr class="row-384 even">
                        <td >Iraq</td><td >Residence Permit</td>
                      </tr><tr class="row-385 odd">
                        <td >Iraq</td><td >Visa</td>
                      </tr><tr class="row-386 even">
                        <td >Iraq</td><td >Voter Card</td>
                      </tr><tr class="row-387 odd">
                        <td >Ireland</td><td >Driver's Licence</td>
                      </tr><tr class="row-388 even">
                        <td >Ireland</td><td >ID Card</td>
                      </tr><tr class="row-389 odd">
                        <td >Ireland</td><td >Passport</td>
                      </tr><tr class="row-390 even">
                        <td >Ireland</td><td >Proof of Age</td>
                      </tr><tr class="row-391 odd">
                        <td >Ireland</td><td >Residence Permit</td>
                      </tr><tr class="row-392 even">
                        <td >Ireland</td><td >Visa</td>
                      </tr><tr class="row-393 odd">
                        <td >Isle of Man</td><td >Driver's Licence</td>
                      </tr><tr class="row-394 even">
                        <td >Isle of Man</td><td >Proof of Age</td>
                      </tr><tr class="row-395 odd">
                        <td >Israel</td><td >Driver's Licence</td>
                      </tr><tr class="row-396 even">
                        <td >Israel</td><td >ID Card</td>
                      </tr><tr class="row-397 odd">
                        <td >Israel</td><td >Passport</td>
                      </tr><tr class="row-398 even">
                        <td >Israel</td><td >Visa</td>
                      </tr><tr class="row-399 odd">
                        <td >Italy</td><td >Driver's Licence</td>
                      </tr><tr class="row-400 even">
                        <td >Italy</td><td >ID Card</td>
                      </tr><tr class="row-401 odd">
                        <td >Italy</td><td >Passport</td>
                      </tr><tr class="row-402 even">
                        <td >Italy</td><td >Residence Permit</td>
                      </tr><tr class="row-403 odd">
                        <td >Italy</td><td >Visa</td>
                      </tr><tr class="row-404 even">
                        <td >Jamaica</td><td >Driver's Licence</td>
                      </tr><tr class="row-405 odd">
                        <td >Jamaica</td><td >ID Card</td>
                      </tr><tr class="row-406 even">
                        <td >Jamaica</td><td >Passport</td>
                      </tr><tr class="row-407 odd">
                        <td >Jamaica</td><td >Voter Card</td>
                      </tr><tr class="row-408 even">
                        <td >Jamaica</td><td >Work Permit</td>
                      </tr><tr class="row-409 odd">
                        <td >Japan</td><td >Driver's Licence</td>
                      </tr><tr class="row-410 even">
                        <td >Japan</td><td >ID Card</td>
                      </tr><tr class="row-411 odd">
                        <td >Japan</td><td >Passport</td>
                      </tr><tr class="row-412 even">
                        <td >Japan</td><td >Residence Permit</td>
                      </tr><tr class="row-413 odd">
                        <td >Japan</td><td >Visa</td>
                      </tr><tr class="row-414 even">
                        <td >Jersey</td><td >Driver's Licence</td>
                      </tr><tr class="row-415 odd">
                        <td >Jordan</td><td >Driver's Licence</td>
                      </tr><tr class="row-416 even">
                        <td >Jordan</td><td >ID Card</td>
                      </tr><tr class="row-417 odd">
                        <td >Jordan</td><td >Passport</td>
                      </tr><tr class="row-418 even">
                        <td >Jordan</td><td >Residence Permit</td>
                      </tr><tr class="row-419 odd">
                        <td >Kazakhstan</td><td >Driver's Licence</td>
                      </tr><tr class="row-420 even">
                        <td >Kazakhstan</td><td >ID Card</td>
                      </tr><tr class="row-421 odd">
                        <td >Kazakhstan</td><td >Passport</td>
                      </tr><tr class="row-422 even">
                        <td >Kazakhstan</td><td >Registration Certificate</td>
                      </tr><tr class="row-423 odd">
                        <td >Kazakhstan</td><td >Residence Permit</td>
                      </tr><tr class="row-424 even">
                        <td >Kazakhstan</td><td >Visa</td>
                      </tr><tr class="row-425 odd">
                        <td >Kenya</td><td >Driver's Licence</td>
                      </tr><tr class="row-426 even">
                        <td >Kenya</td><td >ID Card</td>
                      </tr><tr class="row-427 odd">
                        <td >Kenya</td><td >Passport</td>
                      </tr><tr class="row-428 even">
                        <td >Kenya</td><td >Visa</td>
                      </tr><tr class="row-429 odd">
                        <td >Kiribati</td><td >Passport</td>
                      </tr><tr class="row-430 even">
                        <td >Kosovo</td><td >Driver's Licence</td>
                      </tr><tr class="row-431 odd">
                        <td >Kosovo</td><td >ID Card</td>
                      </tr><tr class="row-432 even">
                        <td >Kosovo</td><td >Passport</td>
                      </tr><tr class="row-433 odd">
                        <td >Kosovo</td><td >Residence Permit</td>
                      </tr><tr class="row-434 even">
                        <td >Kuwait</td><td >Driver's Licence</td>
                      </tr><tr class="row-435 odd">
                        <td >Kuwait</td><td >ID Card</td>
                      </tr><tr class="row-436 even">
                        <td >Kuwait</td><td >Passport</td>
                      </tr><tr class="row-437 odd">
                        <td >Kuwait</td><td >Residence Permit</td>
                      </tr><tr class="row-438 even">
                        <td >Kuwait</td><td >Visa</td>
                      </tr><tr class="row-439 odd">
                        <td >Kyrgyzstan</td><td >Driver's Licence</td>
                      </tr><tr class="row-440 even">
                        <td >Kyrgyzstan</td><td >ID Card</td>
                      </tr><tr class="row-441 odd">
                        <td >Kyrgyzstan</td><td >Passport</td>
                      </tr><tr class="row-442 even">
                        <td >Kyrgyzstan</td><td >Registration Certificate</td>
                      </tr><tr class="row-443 odd">
                        <td >Kyrgyzstan</td><td >Visa</td>
                      </tr><tr class="row-444 even">
                        <td >Lao People's Democratic Republic</td><td >Driver's Licence</td>
                      </tr><tr class="row-445 odd">
                        <td >Lao People's Democratic Republic</td><td >Passport</td>
                      </tr><tr class="row-446 even">
                        <td >Lao People's Democratic Republic</td><td >Visa</td>
                      </tr><tr class="row-447 odd">
                        <td >Latvia</td><td >Driver's Licence</td>
                      </tr><tr class="row-448 even">
                        <td >Latvia</td><td >ID Card</td>
                      </tr><tr class="row-449 odd">
                        <td >Latvia</td><td >Passport</td>
                      </tr><tr class="row-450 even">
                        <td >Latvia</td><td >Registration Certificate</td>
                      </tr><tr class="row-451 odd">
                        <td >Latvia</td><td >Residence Permit</td>
                      </tr><tr class="row-452 even">
                        <td >Latvia</td><td >Visa</td>
                      </tr><tr class="row-453 odd">
                        <td >Lebanon</td><td >Driver's Licence</td>
                      </tr><tr class="row-454 even">
                        <td >Lebanon</td><td >ID Card</td>
                      </tr><tr class="row-455 odd">
                        <td >Lebanon</td><td >Passport</td>
                      </tr><tr class="row-456 even">
                        <td >Lebanon</td><td >Residence Permit</td>
                      </tr><tr class="row-457 odd">
                        <td >Lesotho</td><td >Driver's Licence</td>
                      </tr><tr class="row-458 even">
                        <td >Lesotho</td><td >ID Card</td>
                      </tr><tr class="row-459 odd">
                        <td >Lesotho</td><td >Passport</td>
                      </tr><tr class="row-460 even">
                        <td >Liberia</td><td >Driver's Licence</td>
                      </tr><tr class="row-461 odd">
                        <td >Liberia</td><td >Passport</td>
                      </tr><tr class="row-462 even">
                        <td >Liberia</td><td >Visa</td>
                      </tr><tr class="row-463 odd">
                        <td >Libya</td><td >Driver's Licence</td>
                      </tr><tr class="row-464 even">
                        <td >Libya</td><td >Passport</td>
                      </tr><tr class="row-465 odd">
                        <td >Libya</td><td >Visa</td>
                      </tr><tr class="row-466 even">
                        <td >Liechtenstein</td><td >Driver's Licence</td>
                      </tr><tr class="row-467 odd">
                        <td >Liechtenstein</td><td >ID Card</td>
                      </tr><tr class="row-468 even">
                        <td >Liechtenstein</td><td >Passport</td>
                      </tr><tr class="row-469 odd">
                        <td >Liechtenstein</td><td >Residence Permit</td>
                      </tr><tr class="row-470 even">
                        <td >Lithuania</td><td >Driver's Licence</td>
                      </tr><tr class="row-471 odd">
                        <td >Lithuania</td><td >ID Card</td>
                      </tr><tr class="row-472 even">
                        <td >Lithuania</td><td >Passport</td>
                      </tr><tr class="row-473 odd">
                        <td >Lithuania</td><td >Registration Certificate</td>
                      </tr><tr class="row-474 even">
                        <td >Lithuania</td><td >Residence Permit</td>
                      </tr><tr class="row-475 odd">
                        <td >Lithuania</td><td >Visa</td>
                      </tr><tr class="row-476 even">
                        <td >Luxembourg</td><td >Driver's Licence</td>
                      </tr><tr class="row-477 odd">
                        <td >Luxembourg</td><td >ID Card</td>
                      </tr><tr class="row-478 even">
                        <td >Luxembourg</td><td >Passport</td>
                      </tr><tr class="row-479 odd">
                        <td >Luxembourg</td><td >Residence Permit</td>
                      </tr><tr class="row-480 even">
                        <td >Luxembourg</td><td >Visa</td>
                      </tr><tr class="row-481 odd">
                        <td >Macao</td><td >Driver's Licence</td>
                      </tr><tr class="row-482 even">
                        <td >Macao</td><td >ID Card</td>
                      </tr><tr class="row-483 odd">
                        <td >Macao</td><td >Passport</td>
                      </tr><tr class="row-484 even">
                        <td >Madagascar</td><td >ID Card</td>
                      </tr><tr class="row-485 odd">
                        <td >Madagascar</td><td >Passport</td>
                      </tr><tr class="row-486 even">
                        <td >Madagascar</td><td >Visa</td>
                      </tr><tr class="row-487 odd">
                        <td >Malawi</td><td >Driver's Licence</td>
                      </tr><tr class="row-488 even">
                        <td >Malawi</td><td >ID Card</td>
                      </tr><tr class="row-489 odd">
                        <td >Malawi</td><td >Passport</td>
                      </tr><tr class="row-490 even">
                        <td >Malawi</td><td >Residence Permit</td>
                      </tr><tr class="row-491 odd">
                        <td >Malawi</td><td >Visa</td>
                      </tr><tr class="row-492 even">
                        <td >Malaysia</td><td >Driver's Licence</td>
                      </tr><tr class="row-493 odd">
                        <td >Malaysia</td><td >ID Card</td>
                      </tr><tr class="row-494 even">
                        <td >Malaysia</td><td >Passport</td>
                      </tr><tr class="row-495 odd">
                        <td >Malaysia</td><td >Residence Permit</td>
                      </tr><tr class="row-496 even">
                        <td >Malaysia</td><td >Visa</td>
                      </tr><tr class="row-497 odd">
                        <td >Maldives</td><td >Driver's Licence</td>
                      </tr><tr class="row-498 even">
                        <td >Maldives</td><td >ID Card</td>
                      </tr><tr class="row-499 odd">
                        <td >Maldives</td><td >Passport</td>
                      </tr><tr class="row-500 even">
                        <td >Maldives</td><td >Visa</td>
                      </tr><tr class="row-501 odd">
                        <td >Mali</td><td >ID Card</td>
                      </tr><tr class="row-502 even">
                        <td >Mali</td><td >Passport</td>
                      </tr><tr class="row-503 odd">
                        <td >Mali</td><td >Visa</td>
                      </tr><tr class="row-504 even">
                        <td >Malta</td><td >Driver's Licence</td>
                      </tr><tr class="row-505 odd">
                        <td >Malta</td><td >ID Card</td>
                      </tr><tr class="row-506 even">
                        <td >Malta</td><td >Passport</td>
                      </tr><tr class="row-507 odd">
                        <td >Malta</td><td >Residence Permit</td>
                      </tr><tr class="row-508 even">
                        <td >Malta</td><td >Visa</td>
                      </tr><tr class="row-509 odd">
                        <td >Marshall Islands</td><td >Driver's Licence</td>
                      </tr><tr class="row-510 even">
                        <td >Marshall Islands</td><td >Passport</td>
                      </tr><tr class="row-511 odd">
                        <td >Mauritania</td><td >ID Card</td>
                      </tr><tr class="row-512 even">
                        <td >Mauritania</td><td >Passport</td>
                      </tr><tr class="row-513 odd">
                        <td >Mauritania</td><td >Visa</td>
                      </tr><tr class="row-514 even">
                        <td >Mauritius</td><td >ID Card</td>
                      </tr><tr class="row-515 odd">
                        <td >Mauritius</td><td >Passport</td>
                      </tr><tr class="row-516 even">
                        <td >Mexico</td><td >Driver's Licence</td>
                      </tr><tr class="row-517 odd">
                        <td >Mexico</td><td >ID Card</td>
                      </tr><tr class="row-518 even">
                        <td >Mexico</td><td >Passport</td>
                      </tr><tr class="row-519 odd">
                        <td >Mexico</td><td >Registration Certificate</td>
                      </tr><tr class="row-520 even">
                        <td >Mexico</td><td >Residence Permit</td>
                      </tr><tr class="row-521 odd">
                        <td >Mexico</td><td >Social Security Card</td>
                      </tr><tr class="row-522 even">
                        <td >Mexico</td><td >Visa</td>
                      </tr><tr class="row-523 odd">
                        <td >Mexico</td><td >Voter Card</td>
                      </tr><tr class="row-524 even">
                        <td >Micronesia, Federated States of</td><td >Driver's Licence</td>
                      </tr><tr class="row-525 odd">
                        <td >Micronesia, Federated States of</td><td >ID Card</td>
                      </tr><tr class="row-526 even">
                        <td >Micronesia, Federated States of</td><td >Passport</td>
                      </tr><tr class="row-527 odd">
                        <td >Moldova, Republic of</td><td >Driver's Licence</td>
                      </tr><tr class="row-528 even">
                        <td >Moldova, Republic of</td><td >ID Card</td>
                      </tr><tr class="row-529 odd">
                        <td >Moldova, Republic of</td><td >Passport</td>
                      </tr><tr class="row-530 even">
                        <td >Moldova, Republic of</td><td >Registration Certificate</td>
                      </tr><tr class="row-531 odd">
                        <td >Moldova, Republic of</td><td >Residence Permit</td>
                      </tr><tr class="row-532 even">
                        <td >Moldova, Republic of</td><td >Visa</td>
                      </tr><tr class="row-533 odd">
                        <td >Monaco</td><td >ID Card</td>
                      </tr><tr class="row-534 even">
                        <td >Monaco</td><td >Passport</td>
                      </tr><tr class="row-535 odd">
                        <td >Mongolia</td><td >Driver's Licence</td>
                      </tr><tr class="row-536 even">
                        <td >Mongolia</td><td >ID Card</td>
                      </tr><tr class="row-537 odd">
                        <td >Mongolia</td><td >Passport</td>
                      </tr><tr class="row-538 even">
                        <td >Mongolia</td><td >Visa</td>
                      </tr><tr class="row-539 odd">
                        <td >Montenegro</td><td >Driver's Licence</td>
                      </tr><tr class="row-540 even">
                        <td >Montenegro</td><td >ID Card</td>
                      </tr><tr class="row-541 odd">
                        <td >Montenegro</td><td >Passport</td>
                      </tr><tr class="row-542 even">
                        <td >Montenegro</td><td >Registration Certificate</td>
                      </tr><tr class="row-543 odd">
                        <td >Montenegro</td><td >Residence Permit</td>
                      </tr><tr class="row-544 even">
                        <td >Montserrat</td><td >Passport</td>
                      </tr><tr class="row-545 odd">
                        <td >Morocco</td><td >Driver's Licence</td>
                      </tr><tr class="row-546 even">
                        <td >Morocco</td><td >ID Card</td>
                      </tr><tr class="row-547 odd">
                        <td >Morocco</td><td >Passport</td>
                      </tr><tr class="row-548 even">
                        <td >Morocco</td><td >Registration Certificate</td>
                      </tr><tr class="row-549 odd">
                        <td >Morocco</td><td >Residence Permit</td>
                      </tr><tr class="row-550 even">
                        <td >Morocco</td><td >Visa</td>
                      </tr><tr class="row-551 odd">
                        <td >Mozambique</td><td >Driver's Licence</td>
                      </tr><tr class="row-552 even">
                        <td >Mozambique</td><td >ID Card</td>
                      </tr><tr class="row-553 odd">
                        <td >Mozambique</td><td >Passport</td>
                      </tr><tr class="row-554 even">
                        <td >Mozambique</td><td >Residence Permit</td>
                      </tr><tr class="row-555 odd">
                        <td >Mozambique</td><td >Visa</td>
                      </tr><tr class="row-556 even">
                        <td >Myanmar</td><td >Driver's Licence</td>
                      </tr><tr class="row-557 odd">
                        <td >Myanmar</td><td >Passport</td>
                      </tr><tr class="row-558 even">
                        <td >Myanmar</td><td >Visa</td>
                      </tr><tr class="row-559 odd">
                        <td >Namibia</td><td >Driver's Licence</td>
                      </tr><tr class="row-560 even">
                        <td >Namibia</td><td >ID Card</td>
                      </tr><tr class="row-561 odd">
                        <td >Namibia</td><td >Passport</td>
                      </tr><tr class="row-562 even">
                        <td >Namibia</td><td >Visa</td>
                      </tr><tr class="row-563 odd">
                        <td >Namibia</td><td >Voter Card</td>
                      </tr><tr class="row-564 even">
                        <td >Nauru</td><td >Passport</td>
                      </tr><tr class="row-565 odd">
                        <td >Nepal</td><td >Driver's Licence</td>
                      </tr><tr class="row-566 even">
                        <td >Nepal</td><td >ID Card</td>
                      </tr><tr class="row-567 odd">
                        <td >Nepal</td><td >Passport</td>
                      </tr><tr class="row-568 even">
                        <td >Nepal</td><td >Voter Card</td>
                      </tr><tr class="row-569 odd">
                        <td >Netherlands</td><td >Driver's Licence</td>
                      </tr><tr class="row-570 even">
                        <td >Netherlands</td><td >ID Card</td>
                      </tr><tr class="row-571 odd">
                        <td >Netherlands</td><td >Passport</td>
                      </tr><tr class="row-572 even">
                        <td >Netherlands</td><td >Residence Permit</td>
                      </tr><tr class="row-573 odd">
                        <td >Netherlands</td><td >Visa</td>
                      </tr><tr class="row-574 even">
                        <td >New Zealand</td><td >Driver's Licence</td>
                      </tr><tr class="row-575 odd">
                        <td >New Zealand</td><td >Passport</td>
                      </tr><tr class="row-576 even">
                        <td >New Zealand</td><td >Visa</td>
                      </tr><tr class="row-577 odd">
                        <td >Nicaragua</td><td >Driver's Licence</td>
                      </tr><tr class="row-578 even">
                        <td >Nicaragua</td><td >ID Card</td>
                      </tr><tr class="row-579 odd">
                        <td >Nicaragua</td><td >Passport</td>
                      </tr><tr class="row-580 even">
                        <td >Nicaragua</td><td >Residence Permit</td>
                      </tr><tr class="row-581 odd">
                        <td >Niger</td><td >Passport</td>
                      </tr><tr class="row-582 even">
                        <td >Nigeria</td><td >Driver's Licence</td>
                      </tr><tr class="row-583 odd">
                        <td >Nigeria</td><td >ID Card</td>
                      </tr><tr class="row-584 even">
                        <td >Nigeria</td><td >Passport</td>
                      </tr><tr class="row-585 odd">
                        <td >Nigeria</td><td >Residence Permit</td>
                      </tr><tr class="row-586 even">
                        <td >Nigeria</td><td >Visa</td>
                      </tr><tr class="row-587 odd">
                        <td >Nigeria</td><td >Voter Card</td>
                      </tr><tr class="row-588 even">
                        <td >Niue</td><td >Driver's Licence</td>
                      </tr><tr class="row-589 odd">
                        <td >North Korea</td><td >Passport</td>
                      </tr><tr class="row-590 even">
                        <td >North Korea</td><td >Visa</td>
                      </tr><tr class="row-591 odd">
                        <td >North Macedonia, Republic of</td><td >Driver's Licence</td>
                      </tr><tr class="row-592 even">
                        <td >North Macedonia, Republic of</td><td >ID Card</td>
                      </tr><tr class="row-593 odd">
                        <td >North Macedonia, Republic of</td><td >Passport</td>
                      </tr><tr class="row-594 even">
                        <td >North Macedonia, Republic of</td><td >Residence Permit</td>
                      </tr><tr class="row-595 odd">
                        <td >North Macedonia, Republic of</td><td >Visa</td>
                      </tr><tr class="row-596 even">
                        <td >Northern Cyprus</td><td >ID Card</td>
                      </tr><tr class="row-597 odd">
                        <td >Northern Cyprus</td><td >Passport</td>
                      </tr><tr class="row-598 even">
                        <td >Northern Mariana Islands</td><td >Driver's Licence</td>
                      </tr><tr class="row-599 odd">
                        <td >Norway</td><td >Driver's Licence</td>
                      </tr><tr class="row-600 even">
                        <td >Norway</td><td >ID Card</td>
                      </tr><tr class="row-601 odd">
                        <td >Norway</td><td >Passport</td>
                      </tr><tr class="row-602 even">
                        <td >Norway</td><td >Residence Permit</td>
                      </tr><tr class="row-603 odd">
                        <td >Norway</td><td >Visa</td>
                      </tr><tr class="row-604 even">
                        <td >Oman</td><td >Driver's Licence</td>
                      </tr><tr class="row-605 odd">
                        <td >Oman</td><td >ID Card</td>
                      </tr><tr class="row-606 even">
                        <td >Oman</td><td >Passport</td>
                      </tr><tr class="row-607 odd">
                        <td >Oman</td><td >Residence Permit</td>
                      </tr><tr class="row-608 even">
                        <td >Oman</td><td >Visa</td>
                      </tr><tr class="row-609 odd">
                        <td >Pakistan</td><td >Driver's Licence</td>
                      </tr><tr class="row-610 even">
                        <td >Pakistan</td><td >ID Card</td>
                      </tr><tr class="row-611 odd">
                        <td >Pakistan</td><td >Passport</td>
                      </tr><tr class="row-612 even">
                        <td >Pakistan</td><td >Visa</td>
                      </tr><tr class="row-613 odd">
                        <td >Palau</td><td >Driver's Licence</td>
                      </tr><tr class="row-614 even">
                        <td >Palau</td><td >Passport</td>
                      </tr><tr class="row-615 odd">
                        <td >Palestinian Territory, Occupied</td><td >Driver's Licence</td>
                      </tr><tr class="row-616 even">
                        <td >Palestinian Territory, Occupied</td><td >ID Card</td>
                      </tr><tr class="row-617 odd">
                        <td >Palestinian Territory, Occupied</td><td >Passport</td>
                      </tr><tr class="row-618 even">
                        <td >Panama</td><td >Driver's Licence</td>
                      </tr><tr class="row-619 odd">
                        <td >Panama</td><td >ID Card</td>
                      </tr><tr class="row-620 even">
                        <td >Panama</td><td >Passport</td>
                      </tr><tr class="row-621 odd">
                        <td >Panama</td><td >Residence Permit</td>
                      </tr><tr class="row-622 even">
                        <td >Panama</td><td >Visa</td>
                      </tr><tr class="row-623 odd">
                        <td >Papua New Guinea</td><td >Driver's Licence</td>
                      </tr><tr class="row-624 even">
                        <td >Papua New Guinea</td><td >ID Card</td>
                      </tr><tr class="row-625 odd">
                        <td >Papua New Guinea</td><td >Passport</td>
                      </tr><tr class="row-626 even">
                        <td >Papua New Guinea</td><td >Visa</td>
                      </tr><tr class="row-627 odd">
                        <td >Paraguay</td><td >Driver's Licence</td>
                      </tr><tr class="row-628 even">
                        <td >Paraguay</td><td >ID Card</td>
                      </tr><tr class="row-629 odd">
                        <td >Paraguay</td><td >Passport</td>
                      </tr><tr class="row-630 even">
                        <td >Paraguay</td><td >Registration Certificate</td>
                      </tr><tr class="row-631 odd">
                        <td >Paraguay</td><td >Residence Permit</td>
                      </tr><tr class="row-632 even">
                        <td >Paraguay</td><td >Visa</td>
                      </tr><tr class="row-633 odd">
                        <td >Peru</td><td >Driver's Licence</td>
                      </tr><tr class="row-634 even">
                        <td >Peru</td><td >ID Card</td>
                      </tr><tr class="row-635 odd">
                        <td >Peru</td><td >Passport</td>
                      </tr><tr class="row-636 even">
                        <td >Peru</td><td >Registration Certificate</td>
                      </tr><tr class="row-637 odd">
                        <td >Peru</td><td >Residence Permit</td>
                      </tr><tr class="row-638 even">
                        <td >Peru</td><td >Visa</td>
                      </tr><tr class="row-639 odd">
                        <td >Philippines</td><td >Driver's Licence</td>
                      </tr><tr class="row-640 even">
                        <td >Philippines</td><td >ID Card</td>
                      </tr><tr class="row-641 odd">
                        <td >Philippines</td><td >Passport</td>
                      </tr><tr class="row-642 even">
                        <td >Philippines</td><td >Residence Permit</td>
                      </tr><tr class="row-643 odd">
                        <td >Philippines</td><td >Social Security Card</td>
                      </tr><tr class="row-644 even">
                        <td >Philippines</td><td >Visa</td>
                      </tr><tr class="row-645 odd">
                        <td >Philippines</td><td >Voter Card</td>
                      </tr><tr class="row-646 even">
                        <td >Poland</td><td >Driver's Licence</td>
                      </tr><tr class="row-647 odd">
                        <td >Poland</td><td >ID Card</td>
                      </tr><tr class="row-648 even">
                        <td >Poland</td><td >Passport</td>
                      </tr><tr class="row-649 odd">
                        <td >Poland</td><td >Registration Certificate</td>
                      </tr><tr class="row-650 even">
                        <td >Poland</td><td >Residence Permit</td>
                      </tr><tr class="row-651 odd">
                        <td >Poland</td><td >Visa</td>
                      </tr><tr class="row-652 even">
                        <td >Portugal</td><td >Driver's Licence</td>
                      </tr><tr class="row-653 odd">
                        <td >Portugal</td><td >ID Card</td>
                      </tr><tr class="row-654 even">
                        <td >Portugal</td><td >Passport</td>
                      </tr><tr class="row-655 odd">
                        <td >Portugal</td><td >Registration Certificate</td>
                      </tr><tr class="row-656 even">
                        <td >Portugal</td><td >Residence Permit</td>
                      </tr><tr class="row-657 odd">
                        <td >Portugal</td><td >Visa</td>
                      </tr><tr class="row-658 even">
                        <td >Puerto Rico</td><td >Driver's Licence</td>
                      </tr><tr class="row-659 odd">
                        <td >Puerto Rico</td><td >ID Card</td>
                      </tr><tr class="row-660 even">
                        <td >Puerto Rico</td><td >Voter Card</td>
                      </tr><tr class="row-661 odd">
                        <td >Qatar</td><td >Driver's Licence</td>
                      </tr><tr class="row-662 even">
                        <td >Qatar</td><td >ID Card</td>
                      </tr><tr class="row-663 odd">
                        <td >Qatar</td><td >Passport</td>
                      </tr><tr class="row-664 even">
                        <td >Qatar</td><td >Residence Permit</td>
                      </tr><tr class="row-665 odd">
                        <td >Qatar</td><td >Visa</td>
                      </tr><tr class="row-666 even">
                        <td >Romania</td><td >Driver's Licence</td>
                      </tr><tr class="row-667 odd">
                        <td >Romania</td><td >ID Card</td>
                      </tr><tr class="row-668 even">
                        <td >Romania</td><td >Passport</td>
                      </tr><tr class="row-669 odd">
                        <td >Romania</td><td >Registration Certificate</td>
                      </tr><tr class="row-670 even">
                        <td >Romania</td><td >Residence Permit</td>
                      </tr><tr class="row-671 odd">
                        <td >Romania</td><td >Visa</td>
                      </tr><tr class="row-672 even">
                        <td >Russian Federation</td><td >Driver's Licence</td>
                      </tr><tr class="row-673 odd">
                        <td >Russian Federation</td><td >ID Card</td>
                      </tr><tr class="row-674 even">
                        <td >Russian Federation</td><td >Passport</td>
                      </tr><tr class="row-675 odd">
                        <td >Russian Federation</td><td >Registration Certificate</td>
                      </tr><tr class="row-676 even">
                        <td >Russian Federation</td><td >Residence Permit</td>
                      </tr><tr class="row-677 odd">
                        <td >Russian Federation</td><td >Visa</td>
                      </tr><tr class="row-678 even">
                        <td >Russian Federation</td><td >Work Permit</td>
                      </tr><tr class="row-679 odd">
                        <td >Rwanda</td><td >Driver's Licence</td>
                      </tr><tr class="row-680 even">
                        <td >Rwanda</td><td >ID Card</td>
                      </tr><tr class="row-681 odd">
                        <td >Rwanda</td><td >Passport</td>
                      </tr><tr class="row-682 even">
                        <td >Rwanda</td><td >Visa</td>
                      </tr><tr class="row-683 odd">
                        <td >Saint Helena, Ascension and Tristan da Cunha</td><td >Passport</td>
                      </tr><tr class="row-684 even">
                        <td >Saint Kitts and Nevis</td><td >Driver's Licence</td>
                      </tr><tr class="row-685 odd">
                        <td >Saint Kitts and Nevis</td><td >ID Card</td>
                      </tr><tr class="row-686 even">
                        <td >Saint Kitts and Nevis</td><td >Passport</td>
                      </tr><tr class="row-687 odd">
                        <td >Saint Lucia</td><td >Driver's Licence</td>
                      </tr><tr class="row-688 even">
                        <td >Saint Lucia</td><td >ID Card</td>
                      </tr><tr class="row-689 odd">
                        <td >Saint Lucia</td><td >Passport</td>
                      </tr><tr class="row-690 even">
                        <td >Saint Martin (French part)</td><td >Driver's Licence</td>
                      </tr><tr class="row-691 odd">
                        <td >Saint Martin (French part)</td><td >ID Card</td>
                      </tr><tr class="row-692 even">
                        <td >Saint Vincent and the Grenadines</td><td >Driver's Licence</td>
                      </tr><tr class="row-693 odd">
                        <td >Saint Vincent and the Grenadines</td><td >ID Card</td>
                      </tr><tr class="row-694 even">
                        <td >Saint Vincent and the Grenadines</td><td >Passport</td>
                      </tr><tr class="row-695 odd">
                        <td >Samoa</td><td >Driver's Licence</td>
                      </tr><tr class="row-696 even">
                        <td >Samoa</td><td >Passport</td>
                      </tr><tr class="row-697 odd">
                        <td >San Marino</td><td >Driver's Licence</td>
                      </tr><tr class="row-698 even">
                        <td >San Marino</td><td >ID Card</td>
                      </tr><tr class="row-699 odd">
                        <td >San Marino</td><td >Passport</td>
                      </tr><tr class="row-700 even">
                        <td >Sao Tome and Principe</td><td >ID Card</td>
                      </tr><tr class="row-701 odd">
                        <td >Sao Tome and Principe</td><td >Passport</td>
                      </tr><tr class="row-702 even">
                        <td >Sao Tome and Principe</td><td >Visa</td>
                      </tr><tr class="row-703 odd">
                        <td >Saudi Arabia</td><td >Driver's Licence</td>
                      </tr><tr class="row-704 even">
                        <td >Saudi Arabia</td><td >ID Card</td>
                      </tr><tr class="row-705 odd">
                        <td >Saudi Arabia</td><td >Passport</td>
                      </tr><tr class="row-706 even">
                        <td >Saudi Arabia</td><td >Residence Permit</td>
                      </tr><tr class="row-707 odd">
                        <td >Saudi Arabia</td><td >Visa</td>
                      </tr><tr class="row-708 even">
                        <td >Senegal</td><td >Driver's Licence</td>
                      </tr><tr class="row-709 odd">
                        <td >Senegal</td><td >ID Card</td>
                      </tr><tr class="row-710 even">
                        <td >Senegal</td><td >Passport</td>
                      </tr><tr class="row-711 odd">
                        <td >Senegal</td><td >Visa</td>
                      </tr><tr class="row-712 even">
                        <td >Serbia</td><td >Driver's Licence</td>
                      </tr><tr class="row-713 odd">
                        <td >Serbia</td><td >ID Card</td>
                      </tr><tr class="row-714 even">
                        <td >Serbia</td><td >Passport</td>
                      </tr><tr class="row-715 odd">
                        <td >Serbia</td><td >Registration Certificate</td>
                      </tr><tr class="row-716 even">
                        <td >Serbia</td><td >Visa</td>
                      </tr><tr class="row-717 odd">
                        <td >Seychelles</td><td >Driver's Licence</td>
                      </tr><tr class="row-718 even">
                        <td >Seychelles</td><td >ID Card</td>
                      </tr><tr class="row-719 odd">
                        <td >Seychelles</td><td >Passport</td>
                      </tr><tr class="row-720 even">
                        <td >Sierra Leone</td><td >Driver's Licence</td>
                      </tr><tr class="row-721 odd">
                        <td >Sierra Leone</td><td >ID Card</td>
                      </tr><tr class="row-722 even">
                        <td >Sierra Leone</td><td >Passport</td>
                      </tr><tr class="row-723 odd">
                        <td >Sierra Leone</td><td >Social Security Card</td>
                      </tr><tr class="row-724 even">
                        <td >Sierra Leone</td><td >Visa</td>
                      </tr><tr class="row-725 odd">
                        <td >Sierra Leone</td><td >Voter Card</td>
                      </tr><tr class="row-726 even">
                        <td >Singapore</td><td >Driver's Licence</td>
                      </tr><tr class="row-727 odd">
                        <td >Singapore</td><td >ID Card</td>
                      </tr><tr class="row-728 even">
                        <td >Singapore</td><td >Passport</td>
                      </tr><tr class="row-729 odd">
                        <td >Singapore</td><td >Visa</td>
                      </tr><tr class="row-730 even">
                        <td >Singapore</td><td >Work Permit</td>
                      </tr><tr class="row-731 odd">
                        <td >Sint Maarten (Dutch part)</td><td >Driver's Licence</td>
                      </tr><tr class="row-732 even">
                        <td >Sint Maarten (Dutch part)</td><td >ID Card</td>
                      </tr><tr class="row-733 odd">
                        <td >Slovakia</td><td >Driver's Licence</td>
                      </tr><tr class="row-734 even">
                        <td >Slovakia</td><td >ID Card</td>
                      </tr><tr class="row-735 odd">
                        <td >Slovakia</td><td >Passport</td>
                      </tr><tr class="row-736 even">
                        <td >Slovakia</td><td >Registration Certificate</td>
                      </tr><tr class="row-737 odd">
                        <td >Slovakia</td><td >Residence Permit</td>
                      </tr><tr class="row-738 even">
                        <td >Slovakia</td><td >Visa</td>
                      </tr><tr class="row-739 odd">
                        <td >Slovenia</td><td >Driver's Licence</td>
                      </tr><tr class="row-740 even">
                        <td >Slovenia</td><td >ID Card</td>
                      </tr><tr class="row-741 odd">
                        <td >Slovenia</td><td >Passport</td>
                      </tr><tr class="row-742 even">
                        <td >Slovenia</td><td >Registration Certificate</td>
                      </tr><tr class="row-743 odd">
                        <td >Slovenia</td><td >Residence Permit</td>
                      </tr><tr class="row-744 even">
                        <td >Slovenia</td><td >Visa</td>
                      </tr><tr class="row-745 odd">
                        <td >Solomon Islands</td><td >Passport</td>
                      </tr><tr class="row-746 even">
                        <td >Somalia</td><td >Driver's Licence</td>
                      </tr><tr class="row-747 odd">
                        <td >Somalia</td><td >ID Card</td>
                      </tr><tr class="row-748 even">
                        <td >Somalia</td><td >Passport</td>
                      </tr><tr class="row-749 odd">
                        <td >Somalia</td><td >Registration Certificate</td>
                      </tr><tr class="row-750 even">
                        <td >Somalia</td><td >Visa</td>
                      </tr><tr class="row-751 odd">
                        <td >Somalia</td><td >Work Permit</td>
                      </tr><tr class="row-752 even">
                        <td >Somaliland</td><td >Driver's Licence</td>
                      </tr><tr class="row-753 odd">
                        <td >Somaliland</td><td >ID Card</td>
                      </tr><tr class="row-754 even">
                        <td >Somaliland</td><td >Passport</td>
                      </tr><tr class="row-755 odd">
                        <td >Somaliland</td><td >Voter Card</td>
                      </tr><tr class="row-756 even">
                        <td >South Africa</td><td >Driver's Licence</td>
                      </tr><tr class="row-757 odd">
                        <td >South Africa</td><td >ID Card</td>
                      </tr><tr class="row-758 even">
                        <td >South Africa</td><td >Passport</td>
                      </tr><tr class="row-759 odd">
                        <td >South Africa</td><td >Visa</td>
                      </tr><tr class="row-760 even">
                        <td >South Korea</td><td >Driver's Licence</td>
                      </tr><tr class="row-761 odd">
                        <td >South Korea</td><td >ID Card</td>
                      </tr><tr class="row-762 even">
                        <td >South Korea</td><td >Passport</td>
                      </tr><tr class="row-763 odd">
                        <td >South Korea</td><td >Residence Permit</td>
                      </tr><tr class="row-764 even">
                        <td >South Korea</td><td >Social Security Card</td>
                      </tr><tr class="row-765 odd">
                        <td >South Korea</td><td >Visa</td>
                      </tr><tr class="row-766 even">
                        <td >South Ossetia</td><td >Passport</td>
                      </tr><tr class="row-767 odd">
                        <td >South Sudan</td><td >Driver's Licence</td>
                      </tr><tr class="row-768 even">
                        <td >South Sudan</td><td >ID Card</td>
                      </tr><tr class="row-769 odd">
                        <td >South Sudan</td><td >Passport</td>
                      </tr><tr class="row-770 even">
                        <td >South Sudan</td><td >Visa</td>
                      </tr><tr class="row-771 odd">
                        <td >Spain</td><td >Driver's Licence</td>
                      </tr><tr class="row-772 even">
                        <td >Spain</td><td >ID Card</td>
                      </tr><tr class="row-773 odd">
                        <td >Spain</td><td >Passport</td>
                      </tr><tr class="row-774 even">
                        <td >Spain</td><td >Registration Certificate</td>
                      </tr><tr class="row-775 odd">
                        <td >Spain</td><td >Residence Permit</td>
                      </tr><tr class="row-776 even">
                        <td >Spain</td><td >Visa</td>
                      </tr><tr class="row-777 odd">
                        <td >Sri Lanka</td><td >Driver's Licence</td>
                      </tr><tr class="row-778 even">
                        <td >Sri Lanka</td><td >ID Card</td>
                      </tr><tr class="row-779 odd">
                        <td >Sri Lanka</td><td >Passport</td>
                      </tr><tr class="row-780 even">
                        <td >Sri Lanka</td><td >Work Permit</td>
                      </tr><tr class="row-781 odd">
                        <td >Sudan</td><td >Driver's Licence</td>
                      </tr><tr class="row-782 even">
                        <td >Sudan</td><td >ID Card</td>
                      </tr><tr class="row-783 odd">
                        <td >Sudan</td><td >Passport</td>
                      </tr><tr class="row-784 even">
                        <td >Sudan</td><td >Residence Permit</td>
                      </tr><tr class="row-785 odd">
                        <td >Sudan</td><td >Visa</td>
                      </tr><tr class="row-786 even">
                        <td >Suriname</td><td >Driver's Licence</td>
                      </tr><tr class="row-787 odd">
                        <td >Suriname</td><td >ID Card</td>
                      </tr><tr class="row-788 even">
                        <td >Suriname</td><td >Passport</td>
                      </tr><tr class="row-789 odd">
                        <td >Suriname</td><td >Visa</td>
                      </tr><tr class="row-790 even">
                        <td >Swaziland</td><td >Driver's Licence</td>
                      </tr><tr class="row-791 odd">
                        <td >Swaziland</td><td >ID Card</td>
                      </tr><tr class="row-792 even">
                        <td >Swaziland</td><td >Passport</td>
                      </tr><tr class="row-793 odd">
                        <td >Sweden</td><td >Driver's Licence</td>
                      </tr><tr class="row-794 even">
                        <td >Sweden</td><td >ID Card</td>
                      </tr><tr class="row-795 odd">
                        <td >Sweden</td><td >Passport</td>
                      </tr><tr class="row-796 even">
                        <td >Sweden</td><td >Residence Permit</td>
                      </tr><tr class="row-797 odd">
                        <td >Sweden</td><td >Visa</td>
                      </tr><tr class="row-798 even">
                        <td >Switzerland</td><td >Driver's Licence</td>
                      </tr><tr class="row-799 odd">
                        <td >Switzerland</td><td >ID Card</td>
                      </tr><tr class="row-800 even">
                        <td >Switzerland</td><td >Passport</td>
                      </tr><tr class="row-801 odd">
                        <td >Switzerland</td><td >Residence Permit</td>
                      </tr><tr class="row-802 even">
                        <td >Switzerland</td><td >Visa</td>
                      </tr><tr class="row-803 odd">
                        <td >Syrian Arab Republic</td><td >Driver's Licence</td>
                      </tr><tr class="row-804 even">
                        <td >Syrian Arab Republic</td><td >ID Card</td>
                      </tr><tr class="row-805 odd">
                        <td >Syrian Arab Republic</td><td >Passport</td>
                      </tr><tr class="row-806 even">
                        <td >Syrian Arab Republic</td><td >Visa</td>
                      </tr><tr class="row-807 odd">
                        <td >Taiwan</td><td >Driver's Licence</td>
                      </tr><tr class="row-808 even">
                        <td >Taiwan</td><td >ID Card</td>
                      </tr><tr class="row-809 odd">
                        <td >Taiwan</td><td >Passport</td>
                      </tr><tr class="row-810 even">
                        <td >Taiwan</td><td >Residence Permit</td>
                      </tr><tr class="row-811 odd">
                        <td >Taiwan</td><td >Visa</td>
                      </tr><tr class="row-812 even">
                        <td >Tajikistan</td><td >Driver's Licence</td>
                      </tr><tr class="row-813 odd">
                        <td >Tajikistan</td><td >ID Card</td>
                      </tr><tr class="row-814 even">
                        <td >Tajikistan</td><td >Passport</td>
                      </tr><tr class="row-815 odd">
                        <td >Tajikistan</td><td >Registration Certificate</td>
                      </tr><tr class="row-816 even">
                        <td >Tajikistan</td><td >Visa</td>
                      </tr><tr class="row-817 odd">
                        <td >Tanzania, United Republic of</td><td >Driver's Licence</td>
                      </tr><tr class="row-818 even">
                        <td >Tanzania, United Republic of</td><td >ID Card</td>
                      </tr><tr class="row-819 odd">
                        <td >Tanzania, United Republic of</td><td >Passport</td>
                      </tr><tr class="row-820 even">
                        <td >Tanzania, United Republic of</td><td >Visa</td>
                      </tr><tr class="row-821 odd">
                        <td >Tanzania, United Republic of</td><td >Voter Card</td>
                      </tr><tr class="row-822 even">
                        <td >Thailand</td><td >Driver's Licence</td>
                      </tr><tr class="row-823 odd">
                        <td >Thailand</td><td >ID Card</td>
                      </tr><tr class="row-824 even">
                        <td >Thailand</td><td >Passport</td>
                      </tr><tr class="row-825 odd">
                        <td >Thailand</td><td >Visa</td>
                      </tr><tr class="row-826 even">
                        <td >Timor-Leste</td><td >Driver's Licence</td>
                      </tr><tr class="row-827 odd">
                        <td >Timor-Leste</td><td >Passport</td>
                      </tr><tr class="row-828 even">
                        <td >Timor-Leste</td><td >Residence Permit</td>
                      </tr><tr class="row-829 odd">
                        <td >Togo</td><td >Driver's Licence</td>
                      </tr><tr class="row-830 even">
                        <td >Togo</td><td >ID Card</td>
                      </tr><tr class="row-831 odd">
                        <td >Togo</td><td >Passport</td>
                      </tr><tr class="row-832 even">
                        <td >Togo</td><td >Visa</td>
                      </tr><tr class="row-833 odd">
                        <td >Togo</td><td >Voter Card</td>
                      </tr><tr class="row-834 even">
                        <td >Tonga</td><td >Driver's Licence</td>
                      </tr><tr class="row-835 odd">
                        <td >Tonga</td><td >ID Card</td>
                      </tr><tr class="row-836 even">
                        <td >Tonga</td><td >Passport</td>
                      </tr><tr class="row-837 odd">
                        <td >Transnistria</td><td >Driver's Licence</td>
                      </tr><tr class="row-838 even">
                        <td >Trinidad and Tobago</td><td >Driver's Licence</td>
                      </tr><tr class="row-839 odd">
                        <td >Trinidad and Tobago</td><td >ID Card</td>
                      </tr><tr class="row-840 even">
                        <td >Trinidad and Tobago</td><td >Passport</td>
                      </tr><tr class="row-841 odd">
                        <td >Tunisia</td><td >Driver's Licence</td>
                      </tr><tr class="row-842 even">
                        <td >Tunisia</td><td >ID Card</td>
                      </tr><tr class="row-843 odd">
                        <td >Tunisia</td><td >Passport</td>
                      </tr><tr class="row-844 even">
                        <td >Tunisia</td><td >Residence Permit</td>
                      </tr><tr class="row-845 odd">
                        <td >Tunisia</td><td >Visa</td>
                      </tr><tr class="row-846 even">
                        <td >Turkey</td><td >Driver's Licence</td>
                      </tr><tr class="row-847 odd">
                        <td >Turkey</td><td >ID Card</td>
                      </tr><tr class="row-848 even">
                        <td >Turkey</td><td >Passport</td>
                      </tr><tr class="row-849 odd">
                        <td >Turkey</td><td >Registration Certificate</td>
                      </tr><tr class="row-850 even">
                        <td >Turkey</td><td >Residence Permit</td>
                      </tr><tr class="row-851 odd">
                        <td >Turkey</td><td >Visa</td>
                      </tr><tr class="row-852 even">
                        <td >Turkey</td><td >Work Permit</td>
                      </tr><tr class="row-853 odd">
                        <td >Turkmenistan</td><td >Driver's Licence</td>
                      </tr><tr class="row-854 even">
                        <td >Turkmenistan</td><td >Passport</td>
                      </tr><tr class="row-855 odd">
                        <td >Turkmenistan</td><td >Residence Permit</td>
                      </tr><tr class="row-856 even">
                        <td >Turkmenistan</td><td >Visa</td>
                      </tr><tr class="row-857 odd">
                        <td >Turks and Caicos Islands</td><td >Driver's Licence</td>
                      </tr><tr class="row-858 even">
                        <td >Turks and Caicos Islands</td><td >ID Card</td>
                      </tr><tr class="row-859 odd">
                        <td >Turks and Caicos Islands</td><td >Passport</td>
                      </tr><tr class="row-860 even">
                        <td >Tuvalu</td><td >ID Card</td>
                      </tr><tr class="row-861 odd">
                        <td >Tuvalu</td><td >Passport</td>
                      </tr><tr class="row-862 even">
                        <td >Uganda</td><td >Driver's Licence</td>
                      </tr><tr class="row-863 odd">
                        <td >Uganda</td><td >ID Card</td>
                      </tr><tr class="row-864 even">
                        <td >Uganda</td><td >Passport</td>
                      </tr><tr class="row-865 odd">
                        <td >Uganda</td><td >Visa</td>
                      </tr><tr class="row-866 even">
                        <td >Uganda</td><td >Voter Card</td>
                      </tr><tr class="row-867 odd">
                        <td >Ukraine</td><td >Driver's Licence</td>
                      </tr><tr class="row-868 even">
                        <td >Ukraine</td><td >ID Card</td>
                      </tr><tr class="row-869 odd">
                        <td >Ukraine</td><td >Passport</td>
                      </tr><tr class="row-870 even">
                        <td >Ukraine</td><td >Registration Certificate</td>
                      </tr><tr class="row-871 odd">
                        <td >Ukraine</td><td >Residence Permit</td>
                      </tr><tr class="row-872 even">
                        <td >Ukraine</td><td >Social Security Card</td>
                      </tr><tr class="row-873 odd">
                        <td >Ukraine</td><td >Visa</td>
                      </tr><tr class="row-874 even">
                        <td >United Arab Emirates</td><td >Driver's Licence</td>
                      </tr><tr class="row-875 odd">
                        <td >United Arab Emirates</td><td >ID Card</td>
                      </tr><tr class="row-876 even">
                        <td >United Arab Emirates</td><td >Passport</td>
                      </tr><tr class="row-877 odd">
                        <td >United Arab Emirates</td><td >Registration Certificate</td>
                      </tr><tr class="row-878 even">
                        <td >United Arab Emirates</td><td >Visa</td>
                      </tr><tr class="row-879 odd">
                        <td >United Kingdom</td><td >Driver's Licence</td>
                      </tr><tr class="row-880 even">
                        <td >United Kingdom</td><td >ID Card</td>
                      </tr><tr class="row-881 odd">
                        <td >United Kingdom</td><td >Passport</td>
                      </tr><tr class="row-882 even">
                        <td >United Kingdom</td><td >Proof of Age</td>
                      </tr><tr class="row-883 odd">
                        <td >United Kingdom</td><td >Residence Permit</td>
                      </tr><tr class="row-884 even">
                        <td >United Kingdom</td><td >Visa</td>
                      </tr><tr class="row-885 odd">
                        <td >United States of America</td><td >Driver's Licence</td>
                      </tr><tr class="row-886 even">
                        <td >United States of America</td><td >ID Card</td>
                      </tr><tr class="row-887 odd">
                        <td >United States of America</td><td >Passport</td>
                      </tr><tr class="row-888 even">
                        <td >United States of America</td><td >Residence Permit</td>
                      </tr><tr class="row-889 odd">
                        <td >United States of America</td><td >Visa</td>
                      </tr><tr class="row-890 even">
                        <td >United States of America</td><td >Voter Card</td>
                      </tr><tr class="row-891 odd">
                        <td >Uruguay</td><td >Driver's Licence</td>
                      </tr><tr class="row-892 even">
                        <td >Uruguay</td><td >ID Card</td>
                      </tr><tr class="row-893 odd">
                        <td >Uruguay</td><td >Passport</td>
                      </tr><tr class="row-894 even">
                        <td >Uruguay</td><td >Visa</td>
                      </tr><tr class="row-895 odd">
                        <td >Uzbekistan</td><td >Driver's Licence</td>
                      </tr><tr class="row-896 even">
                        <td >Uzbekistan</td><td >Passport</td>
                      </tr><tr class="row-897 odd">
                        <td >Uzbekistan</td><td >Registration Certificate</td>
                      </tr><tr class="row-898 even">
                        <td >Uzbekistan</td><td >Visa</td>
                      </tr><tr class="row-899 odd">
                        <td >Vanuatu</td><td >Driver's Licence</td>
                      </tr><tr class="row-900 even">
                        <td >Vanuatu</td><td >Passport</td>
                      </tr><tr class="row-901 odd">
                        <td >Venezuela</td><td >Driver's Licence</td>
                      </tr><tr class="row-902 even">
                        <td >Venezuela</td><td >ID Card</td>
                      </tr><tr class="row-903 odd">
                        <td >Venezuela</td><td >Passport</td>
                      </tr><tr class="row-904 even">
                        <td >Venezuela</td><td >Visa</td>
                      </tr><tr class="row-905 odd">
                        <td >Vietnam</td><td >Driver's Licence</td>
                      </tr><tr class="row-906 even">
                        <td >Vietnam</td><td >ID Card</td>
                      </tr><tr class="row-907 odd">
                        <td >Vietnam</td><td >Passport</td>
                      </tr><tr class="row-908 even">
                        <td >Vietnam</td><td >Residence Permit</td>
                      </tr><tr class="row-909 odd">
                        <td >Vietnam</td><td >Visa</td>
                      </tr><tr class="row-910 even">
                        <td >Virgin Islands, British</td><td >Driver's Licence</td>
                      </tr><tr class="row-911 odd">
                        <td >Virgin Islands, British</td><td >Passport</td>
                      </tr><tr class="row-912 even">
                        <td >Virgin Islands, U.S.</td><td >Driver's Licence</td>
                      </tr><tr class="row-913 odd">
                        <td >Western Sahara</td><td >ID Card</td>
                      </tr><tr class="row-914 even">
                        <td >Western Sahara</td><td >Passport</td>
                      </tr><tr class="row-915 odd">
                        <td >Yemen</td><td >Driver's Licence</td>
                      </tr><tr class="row-916 even">
                        <td >Yemen</td><td >ID Card</td>
                      </tr><tr class="row-917 odd">
                        <td >Yemen</td><td >Passport</td>
                      </tr><tr class="row-918 even">
                        <td >Yugoslavia</td><td >Passport</td>
                      </tr><tr class="row-919 odd">
                        <td >Zambia</td><td >Driver's Licence</td>
                      </tr><tr class="row-920 even">
                        <td >Zambia</td><td >ID Card</td>
                      </tr><tr class="row-921 odd">
                        <td >Zambia</td><td >Passport</td>
                      </tr><tr class="row-922 even">
                        <td >Zambia</td><td >Visa</td>
                      </tr><tr class="row-923 odd">
                        <td >Zambia</td><td >Voter Card</td>
                      </tr><tr class="row-924 even">
                        <td >Zimbabwe</td><td >Driver's Licence</td>
                      </tr><tr class="row-925 odd">
                        <td >Zimbabwe</td><td >ID Card</td>
                      </tr><tr class="row-926 even">
                        <td >Zimbabwe</td><td >Passport</td>
                      </tr><tr class="row-927 odd">
                        <td >Zimbabwe</td><td >Visa</td>
                      </tr></tbody>
                  </table>
                </TableContainer>
                <DisclaimerButtonsContainer>
                  <DisclaimerButton
                    style={{ background: "#FFD63E" }}
                    onClick={() => globalContext.openGetStarted()}
                  >
                    Talk to sales
                  </DisclaimerButton>
                </DisclaimerButtonsContainer>
              </Disclaimer>
            </DisclaimerContainer>
          </CustomPageContainer>
        </>
      )}
    </>
  )
}
